<template>
  <div class="confirm-register card" style="padding: 2rem !important; width: 75rem !important;">
    <div class="ant-modal-root order-detail_loader" v-if="loading">
      <div class="ant-modal-mask">
        <div
          class="ant-modal-wrap d-flex justify-content-center align-items-center"
        >
          <a-spin></a-spin>
        </div>
      </div>
    </div>

    <a-row class="mb-5">
      <a-col :span="24" class="d-flex justify-content-center flex-column">
        <div class="title mb-2">
          {{ $t('authForm.titleConfRegis') }}
        </div>

        <div class="subtitle mt-2">
          {{ $t('authForm.subTitleConfRegis') }}
        </div>
      </a-col>
    </a-row>

    <a-form :form="form" @submit.prevent="submit">
      <a-row :gutter="[16, 16]">
        <a-col :span="24" class="my-3">
          <h5 style="color: #000000"> {{ $t('authForm.personalData') }} </h5>
        </a-col>

        <a-col :xs="24" :sm="24" :md="12">
          <a-form-item>
            <label :class="$style.labelInput">{{ $t('form.storeName') }}</label>
            <a-input
              v-decorator="['outlet_name', {
                rules: [{
                  required: true,
                  message: `${$t('form.storeName')} tidak boleh kosong`,
                }],
                initialValue: storeUserData && storeUserData.store && storeUserData.store.name ? storeUserData.store.name : '',
              }]"
              :placeholder="$t('form.placeholderStoreName')"
            />
          </a-form-item>
        </a-col>

        <a-col :xs="24" :sm="24" :md="12">
          <a-form-item>
            <label :class="$style.labelInput">{{ $t('form.owner') }}</label>
            <div style="color: #000000">
              <a-input
                v-decorator="['owner_name', {
                  rules: [{
                    required: true,
                    message: `${$t('form.owner')} tidak boleh kosong`,
                  }],
                  initialValue: responseConfirmLink ? `${responseConfirmLink.first_name} ${responseConfirmLink.last_name}` : '',
                }]"
                disabled
              />
            </div>
          </a-form-item>
        </a-col>

        <a-col :xs="24" :sm="24" :md="12" v-if="responseConfirmLink && responseConfirmLink.phone">
          <a-form-item>
            <label :class="$style.labelInput">{{ $t('form.phone') }}</label>
            <div style="color: #000000">
              <a-input
                v-decorator="['phone', {
                  initialValue: responseConfirmLink ? responseConfirmLink.phone : '',
                }]"
                disabled
              />
            </div>
          </a-form-item>
        </a-col>

        <a-col :xs="24" :sm="24" :md="12" v-if="responseConfirmLink && responseConfirmLink.email" >
          <a-form-item>
            <label :class="$style.labelInput">{{ $t('form.email') }}</label>
            <div style="color: #000000">
              <a-input
                v-decorator="['email', {
                  initialValue: responseConfirmLink ? responseConfirmLink.email : '',
                }]"
                disabled
              />
            </div>
          </a-form-item>
        </a-col>

        <a-col :xs="24" :sm="24" :md="12">
          <a-form-item>
            <label :class="$style.labelInput">{{ $t('form.birthPlace') }}</label>
            <a-input
              v-decorator="['birth_place', {
                rules: [{
                  required: true,
                  message: `${$t('form.birthPlace')} tidak boleh kosong`,
                }],
                initialValue: storeUserData && storeUserData.user && storeUserData.user.birth_place ? storeUserData.user.birth_place : '',
              }]"
              :placeholder="$t('form.placeholderBirthPlace')"
            />
          </a-form-item>
        </a-col>

        <a-col :xs="24" :sm="24" :md="12">
          <a-form-item>
            <label :class="$style.labelInput">{{ $t('form.birthDate') }}</label>
            <a-date-picker
              class="w-100"
              :disabled-date="disabledDate"
              v-decorator="['brith_date', {
                rules: [{
                  required: true,
                  message: `${$t('form.birthDate')} tidak boleh kosong`,
                }],
                initialValue: storeUserData && storeUserData.user && storeUserData.user.birth_date ? storeUserData.user.birth_date : '',
              }]"
            />
          </a-form-item>
        </a-col>

        <a-col :span="24" class="my-3">
          <h5 style="color: #000000">{{$t('form.addressPlace')}}</h5>
        </a-col>

        <a-col :xs="24" :sm="24" :md="24">
          <a-form-item>
            <label :class="$style.labelInput">{{ $t('form.streetName') }}</label>
            <a-input
              v-decorator="['user_street_name', {
                rules: [{
                  required: true,
                  message: `${$t('form.streetName')} tidak boleh kosong`,
                }],
                initialValue: storeUserData && storeUserData.address && storeUserData.address.line1 ? storeUserData.address.line1 : '',
              }]"
              :placeholder="$t('form.placeholderStreetName')"
            />
          </a-form-item>
        </a-col>

        <!-- <a-col :xs="24" :sm="24" :md="12">
          <a-form-item>
            <label :class="$style.labelInput">RT/RW</label>
            <a-input
              v-decorator="['user_street_code', {
                rules: [{
                  required: true
                }]
              }]"
              :placeholder="$t('form.placeholderRTRW')"
            />
          </a-form-item>
        </a-col> -->

        <a-col :xs="24" :sm="24" :md="12">
          <a-form-item>
            <label :class="$style.labelInput">{{ $t('form.country') }}</label>
            <a-select
              :disabled="disableChangeAddress"
              class="select-area"
              show-search
              v-decorator="['user_country', {
                rules: [{
                  required: true,
                  message: `${$t('form.country')} tidak boleh kosong`,
                }],
                initialValue: initialValueCountry && initialValueCountry,
              }]"
              :placeholder="$t('authForm.country')"
              @change="handleSelectCountryUser"
            >
              <a-select-option
                v-for="(item, index) in countryListUser"
                :key="index"
                :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :xs="24" :sm="24" :md="12">
          <a-form-item>
            <label :class="$style.labelInput">{{ $t('form.city') }}</label>
            <a-select
              :disabled="disableChangeAddress"
              class="select-area"
              show-search
              v-decorator="['user_city', {
                rules: [{
                  required: true,
                  message: `${$t('form.city')} tidak boleh kosong`,
                }],
                initialValue: storeUserData && storeUserData.address && storeUserData.address.city_id ? `${storeUserData.address.city_id}` : '',
              }]"
              :filter-option="filterOption"
              :placeholder="$t('form.placeholderCity')"
              @change="handleSelectCityUser"
            >
              <a-select-option
                v-for="(item, index) in cityListUser"
                :value="item.value"
                :key="index"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :xs="24" :sm="24" :md="12">
          <a-form-item>
            <label :class="$style.labelInput">{{ $t('form.area') }}</label>
            <a-select
              :disabled="disableChangeAddress"
              class="select-area"
              show-search
              v-decorator="['user_area', {
                rules: [{
                  required: true,
                  message: `${$t('form.area')} tidak boleh kosong`,
                }],
                initialValue: storeUserData && storeUserData.address && storeUserData.address.area_id ? `${storeUserData.address.area_id}` : '',
              }]"
              :filter-option="filterOption"
              :placeholder="$t('form.placeholderArea')"
              @change="handleSelectAreaUser"
            >
              <a-select-option
                v-for="(item, index) in areaListUser"
                :value="item.value"
                :key="index"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :xs="24" :sm="24" :md="12">
          <a-form-item>
            <label :class="$style.labelInput">{{ $t('authForm.zip') }}</label>
            <a-input
              :disabled="disableChangeAddress"
              v-decorator="['user_code_pos', {
                rules: [{
                  required: true,
                  message: `${$t('authForm.zip')} tidak boleh kosong`,
                }],
                initialValue: storeUserData && storeUserData.address && storeUserData.address.postcode ? storeUserData.address.postcode : '',
              }]"
              :placeholder="$t('authForm.zip')"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="[16, 16]" v-if="checkRegisterConfig('ktp')">
        <a-divider />
        <a-col :xs="24" :sm="24" :md="12">
          <a-form-item>
            <label :class="$style.labelInput">No. KTP</label>
            <a-input
              v-decorator="['identity_number', {
                rules: [{
                  required: metaRegisterConfig?.ktp?.required,
                  message: `No. KTP tidak boleh kosong`,
                }],
                initialValue: storeUserData && storeUserData.user && storeUserData.user.identity_number ? storeUserData.user.identity_number : '',
              }]"
              :placeholder="$t('form.placeholderNoKTP')"
            />
          </a-form-item>
        </a-col>

        <a-col :xs="24" :sm="24" :md="12" id="identity_image">
          <a-form-item>
            <a-upload-dragger
              v-decorator="['identity_image', {
                rules: [{
                  required: metaRegisterConfig?.ktp?.required,
                  message: `File KTP tidak boleh kosong`,
                }],
              }]"
              name="image"
              :multiple="false"
              :show-upload-list="false"
              list-type="picture-card"
              class="image-uploader"
              :beforeUpload="() => false"
              @change="addImageKtp"
              status="uploading"
              v-if="!identity_image"
            >
              <a-spin :spinning="loading_identity_image">
                <p class="ant-upload-drag-icon">
                  <a-icon type="inbox" />
                </p>
                <p class="ant-upload-text">
                  {{ $t('form.clickOrDrag') }}
                </p>
                <p class="ant-upload-hint">
                  {{ $t('form.infoUploadKTP') }}
                </p>
              </a-spin>
            </a-upload-dragger>
          </a-form-item>

          <div class="d-flex justify-content-center" v-if="identity_image && identity_image.url">
            <img :src="identity_image.url" style="width: 25rem; height: auto" />
          </div>

          <div
            @click.prevent="() => identity_image = null"
            v-if="identity_image && identity_image.url"
            class="d-flex justify-content-center align-items-center mt-3"
          >
            <div class="button--changeImage d-flex justify-content-center align-items-center"> {{ $t('form.changeImage') }} </div>
          </div>
        </a-col>
      </a-row>
      <a-row :gutter="[16, 16]" v-if="checkRegisterConfig('npwp')">
        <a-divider />
        <a-col :xs="24" :sm="24" :md="12">
          <a-form-item :label="$t('form.NPWP')">
            <a-input
              v-decorator="['npwp_number', {
                rules: [{
                  required: metaRegisterConfig?.npwp?.required,
                  message: `No. NPWP tidak boleh kosong`,
                }],
              }]"
              :placeholder="$t('form.placeholderNPWP')"
            />
          </a-form-item>
        </a-col>

        <a-col :xs="24" :sm="24" :md="12" id="npwp_image">
          <a-form-item>
            <a-upload-dragger
              v-decorator="['npwp_image', {
                rules: [{
                  required: metaRegisterConfig?.npwp?.required,
                  message: `File NPWP tidak boleh kosong`,
                }],
              }]"
              name="image"
              :multiple="false"
              :show-upload-list="false"
              list-type="picture-card"
              class="image-uploader"
              :beforeUpload="() => false"
              @change="addImageNpwp"
              v-if="!npwp_image"
            >
              <a-spin :spinning="loading_npwp_image">
                <p class="ant-upload-drag-icon">
                  <a-icon type="inbox" />
                </p>
                <p class="ant-upload-text">
                  {{ $t('form.clickOrDrag') }}
                </p>
                <p class="ant-upload-hint">
                  {{ $t('form.infoUploadNPWP') }}
                </p>
              </a-spin>
            </a-upload-dragger>
          </a-form-item>

          <div class="d-flex justify-content-center" v-if="npwp_image && npwp_image.url">
            <img :src="npwp_image.url" style="width: 25rem; height: auto" />
          </div>

          <div
            @click.prevent="() => npwp_image = null"
            v-if="npwp_image && npwp_image.url"
            class="d-flex justify-content-center align-items-center mt-3"
          >
            <div class="button--changeImage d-flex justify-content-center align-items-center"> {{ $t('form.changeImage') }} </div>
          </div>
        </a-col>
      </a-row>
      <a-row :gutter="[16, 16]" v-if="checkRegisterConfig('store')">
        <a-divider />
        <a-col :xs="24" :sm="24" :md="12">
          <a-form-item>
            <p :class="$style.labelInput">{{ $t('authForm.haveStore') }}</p>
            <a-radio-group
              v-decorator="['have_store', {
                rules: [{
                  required: metaRegisterConfig?.store?.required,
                  validator: validatorHaveStore
                }],
                initialValue: true,
              }]"
            >
              <a-radio :value="true">
                {{ $t('form.yes') }}
              </a-radio>
              <a-radio :value="false">
                {{ $t('form.no') }}
              </a-radio>
            </a-radio-group>
          </a-form-item>
        </a-col>

        <a-col :xs="24" :sm="24" :md="12" id="store_image">
          <a-form-item v-if="form.getFieldValue('have_store')">
            <a-upload-dragger
              v-decorator="['store_image', {
                rules: [{
                  required: form.getFieldValue('have_store'),
                  message: `Gambar Toko tidak boleh kosong, jika anda memiliki toko.`,
                }],
              }]"
              name="image"
              :multiple="false"
              :show-upload-list="false"
              list-type="picture-card"
              class="image-uploader"
              :beforeUpload="() => false"
              @change="addImageStore"
              v-if="!store_image"
            >
              <a-spin :spinning="loading_store_image">
                <p class="ant-upload-drag-icon">
                  <a-icon type="inbox" />
                </p>
                <p class="ant-upload-text">
                  {{ $t('form.clickOrDrag') }}
                </p>
                <p class="ant-upload-hint">
                  {{ $t('form.infoUpload') }}
                </p>
              </a-spin>
            </a-upload-dragger>
          </a-form-item>

          <div class="d-flex justify-content-center" v-if="store_image && store_image.url && form.getFieldValue('have_store')">
            <img :src="store_image.url" style="width: 25rem; height: auto" />
          </div>

          <div
            @click.prevent="() => store_image = null"
            v-if="store_image && store_image.url && form.getFieldValue('have_store')"
            class="d-flex justify-content-center align-items-center mt-3"
          >
            <div class="button--changeImage d-flex justify-content-center align-items-center"> {{ $t('form.changeImage') }} </div>
          </div>
        </a-col>
      </a-row>
      <a-row :gutter="[16, 16]" v-if="checkRegisterConfig('store_online')">
        <a-divider />
        <a-col :xs="24" :sm="24" :md="12">
          <a-form-item>
            <p :class="$style.labelInput">{{ $t('authForm.haveOnlineStore') }}</p>
            <a-radio-group
              v-decorator="['have_online_store', {
                rules: [{
                  required: metaRegisterConfig?.online_store?.required,
                  validator: validatorHaveOnlineStore
                }],
                initialValue: true,
              }]"
            >
              <a-radio :value="true">
                {{ $t('form.yes') }}
              </a-radio>
              <a-radio :value="false">
                {{ $t('form.no') }}
              </a-radio>
            </a-radio-group>
          </a-form-item>
        </a-col>

        <a-col :xs="24" :sm="24" :md="12" v-if="form.getFieldValue('have_online_store')">
          <a-row class="mb-3" v-for="(item, index) in listOnlineStore" :key="index">
            <a-col :span="20">
              <a :href="item.url" target="_blank"> {{ item.url }} </a>
            </a-col>
            <a-col style="cursor: pointer" :span="4" class="text-right" @click.prevent="handleDeleteListOnlineStore(index)">
              <a-icon type="delete" />
            </a-col>
          </a-row>

          <a-row class="mb-3" id="inputStore">
            <a-col :span="20">
              <a-form-item>
                <a-input
                  v-decorator="['input_online_store', {
                    rules: [{
                      required: form.getFieldValue('have_online_store'),
                      validator: validatorListUrlStore
                    }],
                  }]"
                  :placeholder="$t('form.placeholderUrlStore')"
                  style="width: 95%; broder-color: #000000 !important;"
                  class="input-store"
                />
              </a-form-item>
            </a-col>
            <a-col :span="4" class="d-flex justify-content-center align-items-center">
              <div
                class="button--addStore d-flex justify-content-center align-items-center"
                @click.prevent="handleClickAddOnlineStore"
              >
                {{ $t('form.add') }}
              </div>
            </a-col>
          </a-row>
        </a-col>
      </a-row>

      <a-divider />

      <a-row :gutter="[16, 16]">
        <a-col :xs="24" :sm="24" :md="12">
          <a-form-item>
            <label :class="$style.labelInput">{{ $t('form.password') }}</label>
            <a-input-password
              v-decorator="['password', {
                rules: [{
                  required: true,
                  message: `${$t('form.password')} tidak boleh kosong`,
                }],
                initialValue: password,
              }]"
              :placeholder="$t('form.placeholder_password')"
              style="width: 95%;"
            />
          </a-form-item>
        </a-col>

        <a-col :xs="24" :sm="24" :md="12">
          <a-button
            class="btn-submit-register"
            type="primary"
            html-type="submit"
            block
          >
            <div v-if="!loading"> {{ $t('form.registerAccount') }} </div>
            <div v-if="loading"> <a-icon type="loading" /> </div>
          </a-button>
        </a-col>
      </a-row>
    </a-form>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getImagePreview } from '@/services/image/utils'
import Cookies from 'js-cookie'
// import axios from '@/services/axios'
// http://localhost:3070/reg-conf/6mWzDY1yj8PzjLv5BwN0
const DEFAULT_REGISTER_CONFIRM_CONFIG = {
  ktp: {
    required: false,
    show: true,
  },
  npwp: {
    required: false,
    show: true,
  },
  store: {
    required: false,
    show: true,
  },
  store_online: {
    required: false,
    show: true,
  },
}
export default {
  name: 'RegisterConfirmation',
  components: {
  },
  data: function () {
    return {
      form: this.$form.createForm(this),
      checkTermCondition: false,
      countryList: [],
      cityList: [],
      areaList: [],
      countryListUser: [],
      cityListUser: [],
      areaListUser: [],
      identity_image: null,
      store_image: null,
      npwp_image: null,
      loading_identity_image: false,
      loading_store_image: false,
      loading_npwp_image: false,
      // error_identity_image: null,
      // error_store_iamge: null,
      // error_npwp_image: null,
      listOnlineStore: [],
      isStoreAddressSameWithUserAddress: true,
      responseConfirmLink: null,
      initialValueCountry: null,
      password: '',
      mapDetail: '',
      latitude: '0',
      longitude: '0',
      storeUserData: null,
      disableChangeAddress: false,
      metaRegisterConfig: DEFAULT_REGISTER_CONFIRM_CONFIG,
    }
  },
  created () {
    this.$store.dispatch('user/LINKCONFIRMATIONDATAREQ', this.$route.params)
      .then(({ data }) => {
        this.responseConfirmLink = data
        this.fetchRegionCountry()
        this.getStoreUserData(data.id)
      })
      .catch(() => {
        this.$router.push('/link-expired')
      })
    const initialPassword = Cookies.get('password-register')
    if (initialPassword) this.password = initialPassword
  },
  watch: {
    '$store.state.meta.data': {
      deep: true,
      immediate: true,
      handler (val) {
        val.forEach((data) => {
          if (data?.meta_name === 'register_confirm_config') {
            try {
              this.metaRegisterConfig = JSON.parse(data?.meta_value || '{}')
            } catch (error) {
              this.metaRegisterConfig = DEFAULT_REGISTER_CONFIRM_CONFIG
            }
          }
        })
      },
    },
  },
  methods: {
    validatorHaveStore(rule, value, callback) {
      if (this.metaRegisterConfig?.store?.required) {
        if (value) {
          callback()
        } else {
          callback(new Error('Anda harus memiliki toko untuk mendaftarkan akun ini'))
        }
      } else {
        callback()
      }
    },
    validatorHaveOnlineStore(rule, value, callback) {
      if (this.metaRegisterConfig?.store_online?.required) {
        if (value) {
          callback()
        } else {
          callback(new Error('Anda harus memiliki toko online untuk mendaftarkan akun ini'))
        }
      } else {
        callback()
      }
    },
    validatorListUrlStore(rule, value, callback) {
      if (this.metaRegisterConfig?.store_online?.required || this.form.getFieldValue('have_online_store')) {
        if (this.listOnlineStore.length > 0) {
          callback()
        } else {
          callback(new Error('Url toko tidak boleh kosong, jika anda memiliki toko.'))
        }
      } else {
        callback()
      }
    },
    checkRegisterConfig(key) {
      const selectedByKey = this.metaRegisterConfig[key]
      if (selectedByKey?.required) {
        return true
      } else if (selectedByKey?.show) {
        return true
      }
      return false
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    disabledDate (current) {
      return current && current > this.$moment().subtract(1, 'day')
    },
    getStoreUserData(id) {
      if (!this.$store.state.user.accessToken) {
        return
      }
      this.$store.dispatch('user/GETDETAILUSER', {
        userId: id,
      })
        .then(({ data }) => {
          this.fetchAreaList(`${data?.address?.city_id}`, 'area', 'user')
          this.storeUserData = data ?? null
          if (data?.store_code?.length > 0) {
            this.disableChangeAddress = true
          }
          // if (data) {
          //   const ktpImage = data?.image_data?.find((obj) => obj.content_id === 10)
          //   this.identity_image = {
          //     url: ktpImage?.path,
          //   }
          // }
        })
        .catch((err) => {
          if (err.response) {
            const { data } = err.response
            this.$notification.error({
              message: data && data.message ? data.message : 'Internal Server Error',
            })
          }
        })
    },
    async compressImage(file) {
      const compress = (file, quality) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = (event) => {
            const img = new Image()
            img.src = event.target.result
            img.onload = () => {
              const canvas = document.createElement('canvas')
              const ctx = canvas.getContext('2d')
              canvas.width = img.width
              canvas.height = img.height
              ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
              canvas.toBlob(
                (blob) => {
                  resolve(blob)
                },
                file.type,
                quality,
              )
            }
          }
          reader.onerror = (error) => reject(error)
        })
      }

      let quality = 0.9
      let compressedFile = file
      while (compressedFile.size > 2000000 && quality > 0.1) {
        compressedFile = await compress(file, quality)
        quality -= 0.1
      }
      return compressedFile
    },
    removeImage () {
      this.image = []
    },
    async addImageKtp({ file, fileList }) {
      this.loading_identity_image = true
      const types = ['image/png', 'image/jpg', 'image/jpeg']
      if (!types.includes(file.type)) {
        this.loading_identity_image = false
        return this.$notification.error({
          message: this.$t('authForm.failUploadImage'),
          description: 'Invalid format file',
        })
      }
      if (file.size > 4000000) {
        this.loading_identity_image = false
        return this.$notification.error({
          message: this.$t('authForm.failUploadImage'),
          description: 'Image Size Max 4 MB',
        })
      }
      const compressedFile = await this.compressImage(file)
      getImagePreview(compressedFile)
        .then(url => {
          this.identity_image = {
            file: compressedFile,
            url,
            fileList,
          }
        })
        .finally(() => {
          this.loading_identity_image = false
        })
    },
    async addImageNpwp ({ file, fileList }) {
      this.loading_npwp_image = true
      const types = ['image/png', 'image/jpg', 'image/jpeg']
      if (!types.includes(file.type)) {
        this.loading_npwp_image = false
        return this.$notification.error({
          message: this.$t('authForm.failUploadImage'),
          description: 'Invalid format file',
        })
      }

      if (file.size > 4000000) {
        this.loading_npwp_image = false
        return this.$notification.error({
          message: this.$t('authForm.failUploadImage'),
          description: 'Image Size Max 4 MB',
        })
      }
      const compressedFile = await this.compressImage(file)
      getImagePreview(compressedFile)
        .then(url => {
          this.npwp_image = {
            file: compressedFile,
            url,
            fileList,
          }
        })
        .finally(() => {
          this.loading_npwp_image = false
        })
    },
    async addImageStore ({ file, fileList }) {
      this.loading_store_image = true
      const types = ['image/png', 'image/jpg', 'image/jpeg']
      if (!types.includes(file.type)) {
        this.loading_store_image = false
        return this.$notification.error({
          message: this.$t('authForm.failUploadImage'),
          description: 'Invalid format file',
        })
      }

      if (file.size > 4000000) {
        this.loading_store_image = false
        return this.$notification.error({
          message: this.$t('authForm.failUploadImage'),
          description: 'Image Size Max 4 MB',
        })
      }
      const compressedFile = await this.compressImage(file)
      getImagePreview(compressedFile)
        .then(url => {
          this.store_image = {
            file: compressedFile,
            url,
            fileList,
          }
        })
        .finally(() => {
          this.loading_store_image = false
        })
    },
    removeImageNpwp () {
      this.imageNpwp = []
    },
    handleCheckbox (e) {
      this.checkTermCondition = e.target.checked
    },
    hanldeChangeAddressStore (e) {
      this.isStoreAddressSameWithUserAddress = e.target.checked
    },
    fetchRegionCountry () {
      this.$store.dispatch('data/GETREGIONCOUNTRY', this.$store.state.app.vendor_hashid)
        .then((data) => {
          this.countryList = data.list
          this.countryListUser = data.list

          const getIndonesia = data.list.find(item => item.label.toLowerCase() === 'indonesia')

          if (getIndonesia) {
            this.handleSelectCountry(getIndonesia.value)
            this.handleSelectCountryUser(getIndonesia.value)
            this.initialValueCountry = getIndonesia.value
          }
        })
    },
    handleSelectCountry (e) {
      this.fetchAreaList(e, 'city')
    },
    handleSelectCountryUser (e) {
      this.fetchAreaList(e, 'city', 'user')
    },
    fetchAreaList (parentId, param, state) {
      this.$store.dispatch('data/GETAREALLIST', {
        parentId,
      })
        .then((data) => {
          if (state === 'user') {
            switch (param) {
              case 'city':
                this.cityListUser = data.list
                break

              case 'area':
                this.areaListUser = data.list
                break

              default:
                break
            }
          } else {
            switch (param) {
              case 'city':
                this.cityList = data.list
                break

              case 'area':
                this.areaList = data.list
                break

              default:
                break
            }
          }
        })
    },
    handleSelectCityUser (e) {
      const key = this.cityListUser.find(item => item.value === e)
      this.fetchAreaList(key.value, 'area', 'user')
    },
    handleSelectAreaUser (e) {
      const findArea = this.areaListUser.find(item => item.value === e)
      this.form.setFieldsValue({
        user_code_pos: findArea.postcode,
      })
    },
    handleClickAddOnlineStore () {
      if (!this.form.getFieldValue('input_online_store')) {
        return this.$notification.error({
          message: this.$t('authForm.insertYourUrlStore'),
        })
      }

      this.listOnlineStore.push({
        url: this.form.getFieldValue('input_online_store'),
      })
      this.form.resetFields(['input_online_store'])
    },
    handleDeleteListOnlineStore (indexParam) {
      this.form.resetFields(['input_online_store'])
      this.listOnlineStore = this.listOnlineStore.filter((item, index) => index !== indexParam)
    },
    getAreaId (label, param) {
      let data
      if (param === 'user') {
        data = this.areaListUser.find(item => item.label === label)
      } else {
        data = this.areaList.find(item => item.label === label)
      }

      return data ? +data.value : null
    },
    getCityId (label, param) {
      let data
      if (param === 'user') {
        data = this.cityListUser.find(item => item.label === label)
      } else {
        data = this.cityList.find(item => item.label === label)
      }

      return data ? +data.value : null
    },
    submit () {
      this.form.validateFieldsAndScroll(async (errors, values) => {
        if (errors) {
          return
        }

        // if (!this.identity_image) {
        //   const element = document.getElementById('identity_image')
        //   element.scrollIntoView({
        //     behavior: 'smooth',
        //   })
        //   return this.$notification.error({
        //     message: 'Please upload your ID-Card',
        //   })
        // }

        // if (!this.npwp_image) {
        //   const element = document.getElementById('npwp_image')
        //   element.scrollIntoView({
        //     behavior: 'smooth',
        //   })
        //   return this.$notification.error({
        //     message: 'Please upload your NPWP Card',
        //   })
        // }

        // if (!this.store_image && values.have_store) {
        //   const element = document.getElementById('store_image')
        //   element.scrollIntoView({
        //     behavior: 'smooth',
        //   })
        //   return this.$notification.error({
        //     message: 'Please upload your store foto',
        //   })
        // }

        // if (values.have_online_store && !this.listOnlineStore.length) {
        //   const element = document.getElementById('inputStore')
        //   element.scrollIntoView({
        //     behavior: 'smooth',
        //   })
        //   return this.$notification.error({
        //     message: 'Please insert your online store url',
        //   })
        // }

        const address_user = {
          line1: values.user_street_name + ' ' + values.user_street_code,
          line2: null,
          country_id: +values.user_country,
          area_id: +values.user_area,
          city_id: +values.user_city,
          postcode: +values.user_code_pos,
          map_detail: this.mapDetail,
          latitude: this.latitude,
          longitude: this.longitude,
        }

        const body = {
          channel_id: this.responseConfirmLink.channel_id || this.$store.state.app.channel_id,
          is_needconfirm: false,
          user: {
            register_id: this.responseConfirmLink.register_id,
            id: this.responseConfirmLink.id,
            first_name: this.responseConfirmLink.first_name,
            last_name: this.responseConfirmLink.last_name,
            email: this.responseConfirmLink.email,
            phone: this.responseConfirmLink.phone,
            identity_number: values.identity_number,
            address: {
              ...address_user,
            },
            birth_place: values.birth_place,
            birth_date: values.brith_date,
          },
          code: '',
          address: {
            ...address_user,
          },
          password: values.password,
          link: this.$route.params.linkId,
          store: {
            name: values.outlet_name,
            type: values.have_store ? 'STORE' : 'RESELLER',
            url: values.have_online_store ? this.listOnlineStore : [],
            npwp: values.npwp_number,
          },
        }

        const promises = []

        if (this.identity_image) {
          const formData = new FormData()
          formData.append('image', this.identity_image.file)
          formData.append('userId', body.user.id)
          formData.append('contentId', 10)
          promises.push(this.$store.dispatch('user/UPLOADIMAGE', formData))
        }

        if (this.npwp_image) {
          const formData = new FormData()
          formData.append('image', this.npwp_image.file)
          formData.append('userId', body.user.id)
          formData.append('contentId', 17)
          promises.push(this.$store.dispatch('user/UPLOADIMAGE', formData))
        }

        if (values.have_store && this.store_image) {
          const formData = new FormData()
          formData.append('image', this.store_image.file)
          formData.append('userId', body.user.id)
          formData.append('contentId', 23)
          promises.push(this.$store.dispatch('user/UPLOADIMAGE', formData))
        }

        let image_data = []

        Promise.all(promises)
          .then((response) => {
            image_data = response.map(item => ({
              path: item.data.image_path,
              name: item.data.image_name,
              size: item.data.image_size,
              type: item.data.image_type,
              ref_id: item.data.ref_id,
              content_id: item.data.content_id,
              ...item.data,
            }))

            body.image_data = image_data
            return this.$store.dispatch('user/REGISTERCONFIRMATION', { ...body })
          })
          .then(_ => {
            this.$router.push('/success-register')
          })
          .catch(err => {
            this.$notification.error({
              message: this.$t('authForm.registerFailed'),
              description: err.response?.data?.message || err.message,
            })
          })
      })
    },
  },
  computed: {
    ...mapState('user', {
      base_url: state => state.base_url,
    }),
    loading() {
      return this.$store.state.user.loading
    },
  },
}
</script>

<style lang="scss" module>
@import "@/components/cleanui/system/Auth/style.module.scss";
</style>

<style lang="scss">
.confirm-register {
  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #000000;
  }

  .subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #585858;
  }

  .ant-form-item-label {
    label {
      color: #000000 !important;
    }
  }

  .ant-form-item-control {
    input {
      border: 1px solid #000000;
      box-sizing: border-box;
      border-radius: 8px;
    }
  }

  .ant-select-selection {
    border: 1px solid #000000 !important;
    box-sizing: border-box !important;
    border-radius: 8px !important;
  }

  .ant-select-search {
    &__field {
      &__wrap {
        input {
          border: none !important;
          box-sizing: none !important;
          border-radius: none !important;
        }
      }
    }
  }

  .button {
    &--changeImage {
      cursor: pointer;
      color: var(--biz-brand-button);
      border-radius: 5px;
      height: 30px;
      border: 1px solid var(--biz-brand-button);
      width: 40%;
    }

    &--changeImage:hover {
      color: #FFFFFF;
      background: var(--biz-brand-button);
    }

    &--addStore {
      color: #FFFFFF;
      background: var(--biz-brand-button);
      border-radius: 5px;
      cursor: pointer;
      width: 100px;
      height: 38px;
      padding: .75rem;
    }
  }
}

.image-uploader {
  .ant-upload-select-picture-card {
    margin: 0 !important;
    width: 140px !important;
    height: 140px !important;

    img {
      max-width: 100%
    }

    i {
      font-size: 32px;
      color: #999;
    }

    .ant-upload-text {
      margin-top: 8px;
      color: #666;
    }
  }
}
.pac-container {
  margin-top: 10px;
}
.btn-submit-register {
  width: 95% !important;
  background: var(--biz-brand-button) !important;
  color: #FFFFFF !important;
  border: 1px solid var(--biz-brand-button) !important;
  margin-top: 43px;
}
.select-area {
  &.ant-select-disabled{
    color: #000000 !important;
  }
}
</style>
