var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"confirm-register card",staticStyle:{"padding":"2rem !important","width":"75rem !important"}},[(_vm.loading)?_c('div',{staticClass:"ant-modal-root order-detail_loader"},[_c('div',{staticClass:"ant-modal-mask"},[_c('div',{staticClass:"ant-modal-wrap d-flex justify-content-center align-items-center"},[_c('a-spin')],1)])]):_vm._e(),_c('a-row',{staticClass:"mb-5"},[_c('a-col',{staticClass:"d-flex justify-content-center flex-column",attrs:{"span":24}},[_c('div',{staticClass:"title mb-2"},[_vm._v(" "+_vm._s(_vm.$t('authForm.titleConfRegis'))+" ")]),_c('div',{staticClass:"subtitle mt-2"},[_vm._v(" "+_vm._s(_vm.$t('authForm.subTitleConfRegis'))+" ")])])],1),_c('a-form',{attrs:{"form":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('a-row',{attrs:{"gutter":[16, 16]}},[_c('a-col',{staticClass:"my-3",attrs:{"span":24}},[_c('h5',{staticStyle:{"color":"#000000"}},[_vm._v(" "+_vm._s(_vm.$t('authForm.personalData'))+" ")])]),_c('a-col',{attrs:{"xs":24,"sm":24,"md":12}},[_c('a-form-item',[_c('label',{class:_vm.$style.labelInput},[_vm._v(_vm._s(_vm.$t('form.storeName')))]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['outlet_name', {
              rules: [{
                required: true,
                message: `${_vm.$t('form.storeName')} tidak boleh kosong`,
              }],
              initialValue: _vm.storeUserData && _vm.storeUserData.store && _vm.storeUserData.store.name ? _vm.storeUserData.store.name : '',
            }]),expression:"['outlet_name', {\n              rules: [{\n                required: true,\n                message: `${$t('form.storeName')} tidak boleh kosong`,\n              }],\n              initialValue: storeUserData && storeUserData.store && storeUserData.store.name ? storeUserData.store.name : '',\n            }]"}],attrs:{"placeholder":_vm.$t('form.placeholderStoreName')}})],1)],1),_c('a-col',{attrs:{"xs":24,"sm":24,"md":12}},[_c('a-form-item',[_c('label',{class:_vm.$style.labelInput},[_vm._v(_vm._s(_vm.$t('form.owner')))]),_c('div',{staticStyle:{"color":"#000000"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['owner_name', {
                rules: [{
                  required: true,
                  message: `${_vm.$t('form.owner')} tidak boleh kosong`,
                }],
                initialValue: _vm.responseConfirmLink ? `${_vm.responseConfirmLink.first_name} ${_vm.responseConfirmLink.last_name}` : '',
              }]),expression:"['owner_name', {\n                rules: [{\n                  required: true,\n                  message: `${$t('form.owner')} tidak boleh kosong`,\n                }],\n                initialValue: responseConfirmLink ? `${responseConfirmLink.first_name} ${responseConfirmLink.last_name}` : '',\n              }]"}],attrs:{"disabled":""}})],1)])],1),(_vm.responseConfirmLink && _vm.responseConfirmLink.phone)?_c('a-col',{attrs:{"xs":24,"sm":24,"md":12}},[_c('a-form-item',[_c('label',{class:_vm.$style.labelInput},[_vm._v(_vm._s(_vm.$t('form.phone')))]),_c('div',{staticStyle:{"color":"#000000"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['phone', {
                initialValue: _vm.responseConfirmLink ? _vm.responseConfirmLink.phone : '',
              }]),expression:"['phone', {\n                initialValue: responseConfirmLink ? responseConfirmLink.phone : '',\n              }]"}],attrs:{"disabled":""}})],1)])],1):_vm._e(),(_vm.responseConfirmLink && _vm.responseConfirmLink.email)?_c('a-col',{attrs:{"xs":24,"sm":24,"md":12}},[_c('a-form-item',[_c('label',{class:_vm.$style.labelInput},[_vm._v(_vm._s(_vm.$t('form.email')))]),_c('div',{staticStyle:{"color":"#000000"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['email', {
                initialValue: _vm.responseConfirmLink ? _vm.responseConfirmLink.email : '',
              }]),expression:"['email', {\n                initialValue: responseConfirmLink ? responseConfirmLink.email : '',\n              }]"}],attrs:{"disabled":""}})],1)])],1):_vm._e(),_c('a-col',{attrs:{"xs":24,"sm":24,"md":12}},[_c('a-form-item',[_c('label',{class:_vm.$style.labelInput},[_vm._v(_vm._s(_vm.$t('form.birthPlace')))]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['birth_place', {
              rules: [{
                required: true,
                message: `${_vm.$t('form.birthPlace')} tidak boleh kosong`,
              }],
              initialValue: _vm.storeUserData && _vm.storeUserData.user && _vm.storeUserData.user.birth_place ? _vm.storeUserData.user.birth_place : '',
            }]),expression:"['birth_place', {\n              rules: [{\n                required: true,\n                message: `${$t('form.birthPlace')} tidak boleh kosong`,\n              }],\n              initialValue: storeUserData && storeUserData.user && storeUserData.user.birth_place ? storeUserData.user.birth_place : '',\n            }]"}],attrs:{"placeholder":_vm.$t('form.placeholderBirthPlace')}})],1)],1),_c('a-col',{attrs:{"xs":24,"sm":24,"md":12}},[_c('a-form-item',[_c('label',{class:_vm.$style.labelInput},[_vm._v(_vm._s(_vm.$t('form.birthDate')))]),_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['brith_date', {
              rules: [{
                required: true,
                message: `${_vm.$t('form.birthDate')} tidak boleh kosong`,
              }],
              initialValue: _vm.storeUserData && _vm.storeUserData.user && _vm.storeUserData.user.birth_date ? _vm.storeUserData.user.birth_date : '',
            }]),expression:"['brith_date', {\n              rules: [{\n                required: true,\n                message: `${$t('form.birthDate')} tidak boleh kosong`,\n              }],\n              initialValue: storeUserData && storeUserData.user && storeUserData.user.birth_date ? storeUserData.user.birth_date : '',\n            }]"}],staticClass:"w-100",attrs:{"disabled-date":_vm.disabledDate}})],1)],1),_c('a-col',{staticClass:"my-3",attrs:{"span":24}},[_c('h5',{staticStyle:{"color":"#000000"}},[_vm._v(_vm._s(_vm.$t('form.addressPlace')))])]),_c('a-col',{attrs:{"xs":24,"sm":24,"md":24}},[_c('a-form-item',[_c('label',{class:_vm.$style.labelInput},[_vm._v(_vm._s(_vm.$t('form.streetName')))]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['user_street_name', {
              rules: [{
                required: true,
                message: `${_vm.$t('form.streetName')} tidak boleh kosong`,
              }],
              initialValue: _vm.storeUserData && _vm.storeUserData.address && _vm.storeUserData.address.line1 ? _vm.storeUserData.address.line1 : '',
            }]),expression:"['user_street_name', {\n              rules: [{\n                required: true,\n                message: `${$t('form.streetName')} tidak boleh kosong`,\n              }],\n              initialValue: storeUserData && storeUserData.address && storeUserData.address.line1 ? storeUserData.address.line1 : '',\n            }]"}],attrs:{"placeholder":_vm.$t('form.placeholderStreetName')}})],1)],1),_c('a-col',{attrs:{"xs":24,"sm":24,"md":12}},[_c('a-form-item',[_c('label',{class:_vm.$style.labelInput},[_vm._v(_vm._s(_vm.$t('form.country')))]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['user_country', {
              rules: [{
                required: true,
                message: `${_vm.$t('form.country')} tidak boleh kosong`,
              }],
              initialValue: _vm.initialValueCountry && _vm.initialValueCountry,
            }]),expression:"['user_country', {\n              rules: [{\n                required: true,\n                message: `${$t('form.country')} tidak boleh kosong`,\n              }],\n              initialValue: initialValueCountry && initialValueCountry,\n            }]"}],staticClass:"select-area",attrs:{"disabled":_vm.disableChangeAddress,"show-search":"","placeholder":_vm.$t('authForm.country')},on:{"change":_vm.handleSelectCountryUser}},_vm._l((_vm.countryListUser),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"xs":24,"sm":24,"md":12}},[_c('a-form-item',[_c('label',{class:_vm.$style.labelInput},[_vm._v(_vm._s(_vm.$t('form.city')))]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['user_city', {
              rules: [{
                required: true,
                message: `${_vm.$t('form.city')} tidak boleh kosong`,
              }],
              initialValue: _vm.storeUserData && _vm.storeUserData.address && _vm.storeUserData.address.city_id ? `${_vm.storeUserData.address.city_id}` : '',
            }]),expression:"['user_city', {\n              rules: [{\n                required: true,\n                message: `${$t('form.city')} tidak boleh kosong`,\n              }],\n              initialValue: storeUserData && storeUserData.address && storeUserData.address.city_id ? `${storeUserData.address.city_id}` : '',\n            }]"}],staticClass:"select-area",attrs:{"disabled":_vm.disableChangeAddress,"show-search":"","filter-option":_vm.filterOption,"placeholder":_vm.$t('form.placeholderCity')},on:{"change":_vm.handleSelectCityUser}},_vm._l((_vm.cityListUser),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"xs":24,"sm":24,"md":12}},[_c('a-form-item',[_c('label',{class:_vm.$style.labelInput},[_vm._v(_vm._s(_vm.$t('form.area')))]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['user_area', {
              rules: [{
                required: true,
                message: `${_vm.$t('form.area')} tidak boleh kosong`,
              }],
              initialValue: _vm.storeUserData && _vm.storeUserData.address && _vm.storeUserData.address.area_id ? `${_vm.storeUserData.address.area_id}` : '',
            }]),expression:"['user_area', {\n              rules: [{\n                required: true,\n                message: `${$t('form.area')} tidak boleh kosong`,\n              }],\n              initialValue: storeUserData && storeUserData.address && storeUserData.address.area_id ? `${storeUserData.address.area_id}` : '',\n            }]"}],staticClass:"select-area",attrs:{"disabled":_vm.disableChangeAddress,"show-search":"","filter-option":_vm.filterOption,"placeholder":_vm.$t('form.placeholderArea')},on:{"change":_vm.handleSelectAreaUser}},_vm._l((_vm.areaListUser),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"xs":24,"sm":24,"md":12}},[_c('a-form-item',[_c('label',{class:_vm.$style.labelInput},[_vm._v(_vm._s(_vm.$t('authForm.zip')))]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['user_code_pos', {
              rules: [{
                required: true,
                message: `${_vm.$t('authForm.zip')} tidak boleh kosong`,
              }],
              initialValue: _vm.storeUserData && _vm.storeUserData.address && _vm.storeUserData.address.postcode ? _vm.storeUserData.address.postcode : '',
            }]),expression:"['user_code_pos', {\n              rules: [{\n                required: true,\n                message: `${$t('authForm.zip')} tidak boleh kosong`,\n              }],\n              initialValue: storeUserData && storeUserData.address && storeUserData.address.postcode ? storeUserData.address.postcode : '',\n            }]"}],attrs:{"disabled":_vm.disableChangeAddress,"placeholder":_vm.$t('authForm.zip')}})],1)],1)],1),(_vm.checkRegisterConfig('ktp'))?_c('a-row',{attrs:{"gutter":[16, 16]}},[_c('a-divider'),_c('a-col',{attrs:{"xs":24,"sm":24,"md":12}},[_c('a-form-item',[_c('label',{class:_vm.$style.labelInput},[_vm._v("No. KTP")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['identity_number', {
              rules: [{
                required: _vm.metaRegisterConfig?.ktp?.required,
                message: `No. KTP tidak boleh kosong`,
              }],
              initialValue: _vm.storeUserData && _vm.storeUserData.user && _vm.storeUserData.user.identity_number ? _vm.storeUserData.user.identity_number : '',
            }]),expression:"['identity_number', {\n              rules: [{\n                required: metaRegisterConfig?.ktp?.required,\n                message: `No. KTP tidak boleh kosong`,\n              }],\n              initialValue: storeUserData && storeUserData.user && storeUserData.user.identity_number ? storeUserData.user.identity_number : '',\n            }]"}],attrs:{"placeholder":_vm.$t('form.placeholderNoKTP')}})],1)],1),_c('a-col',{attrs:{"xs":24,"sm":24,"md":12,"id":"identity_image"}},[_c('a-form-item',[(!_vm.identity_image)?_c('a-upload-dragger',{directives:[{name:"decorator",rawName:"v-decorator",value:(['identity_image', {
              rules: [{
                required: _vm.metaRegisterConfig?.ktp?.required,
                message: `File KTP tidak boleh kosong`,
              }],
            }]),expression:"['identity_image', {\n              rules: [{\n                required: metaRegisterConfig?.ktp?.required,\n                message: `File KTP tidak boleh kosong`,\n              }],\n            }]"}],staticClass:"image-uploader",attrs:{"name":"image","multiple":false,"show-upload-list":false,"list-type":"picture-card","beforeUpload":() => false,"status":"uploading"},on:{"change":_vm.addImageKtp}},[_c('a-spin',{attrs:{"spinning":_vm.loading_identity_image}},[_c('p',{staticClass:"ant-upload-drag-icon"},[_c('a-icon',{attrs:{"type":"inbox"}})],1),_c('p',{staticClass:"ant-upload-text"},[_vm._v(" "+_vm._s(_vm.$t('form.clickOrDrag'))+" ")]),_c('p',{staticClass:"ant-upload-hint"},[_vm._v(" "+_vm._s(_vm.$t('form.infoUploadKTP'))+" ")])])],1):_vm._e()],1),(_vm.identity_image && _vm.identity_image.url)?_c('div',{staticClass:"d-flex justify-content-center"},[_c('img',{staticStyle:{"width":"25rem","height":"auto"},attrs:{"src":_vm.identity_image.url}})]):_vm._e(),(_vm.identity_image && _vm.identity_image.url)?_c('div',{staticClass:"d-flex justify-content-center align-items-center mt-3",on:{"click":function($event){$event.preventDefault();return (() => _vm.identity_image = null).apply(null, arguments)}}},[_c('div',{staticClass:"button--changeImage d-flex justify-content-center align-items-center"},[_vm._v(" "+_vm._s(_vm.$t('form.changeImage'))+" ")])]):_vm._e()],1)],1):_vm._e(),(_vm.checkRegisterConfig('npwp'))?_c('a-row',{attrs:{"gutter":[16, 16]}},[_c('a-divider'),_c('a-col',{attrs:{"xs":24,"sm":24,"md":12}},[_c('a-form-item',{attrs:{"label":_vm.$t('form.NPWP')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['npwp_number', {
              rules: [{
                required: _vm.metaRegisterConfig?.npwp?.required,
                message: `No. NPWP tidak boleh kosong`,
              }],
            }]),expression:"['npwp_number', {\n              rules: [{\n                required: metaRegisterConfig?.npwp?.required,\n                message: `No. NPWP tidak boleh kosong`,\n              }],\n            }]"}],attrs:{"placeholder":_vm.$t('form.placeholderNPWP')}})],1)],1),_c('a-col',{attrs:{"xs":24,"sm":24,"md":12,"id":"npwp_image"}},[_c('a-form-item',[(!_vm.npwp_image)?_c('a-upload-dragger',{directives:[{name:"decorator",rawName:"v-decorator",value:(['npwp_image', {
              rules: [{
                required: _vm.metaRegisterConfig?.npwp?.required,
                message: `File NPWP tidak boleh kosong`,
              }],
            }]),expression:"['npwp_image', {\n              rules: [{\n                required: metaRegisterConfig?.npwp?.required,\n                message: `File NPWP tidak boleh kosong`,\n              }],\n            }]"}],staticClass:"image-uploader",attrs:{"name":"image","multiple":false,"show-upload-list":false,"list-type":"picture-card","beforeUpload":() => false},on:{"change":_vm.addImageNpwp}},[_c('a-spin',{attrs:{"spinning":_vm.loading_npwp_image}},[_c('p',{staticClass:"ant-upload-drag-icon"},[_c('a-icon',{attrs:{"type":"inbox"}})],1),_c('p',{staticClass:"ant-upload-text"},[_vm._v(" "+_vm._s(_vm.$t('form.clickOrDrag'))+" ")]),_c('p',{staticClass:"ant-upload-hint"},[_vm._v(" "+_vm._s(_vm.$t('form.infoUploadNPWP'))+" ")])])],1):_vm._e()],1),(_vm.npwp_image && _vm.npwp_image.url)?_c('div',{staticClass:"d-flex justify-content-center"},[_c('img',{staticStyle:{"width":"25rem","height":"auto"},attrs:{"src":_vm.npwp_image.url}})]):_vm._e(),(_vm.npwp_image && _vm.npwp_image.url)?_c('div',{staticClass:"d-flex justify-content-center align-items-center mt-3",on:{"click":function($event){$event.preventDefault();return (() => _vm.npwp_image = null).apply(null, arguments)}}},[_c('div',{staticClass:"button--changeImage d-flex justify-content-center align-items-center"},[_vm._v(" "+_vm._s(_vm.$t('form.changeImage'))+" ")])]):_vm._e()],1)],1):_vm._e(),(_vm.checkRegisterConfig('store'))?_c('a-row',{attrs:{"gutter":[16, 16]}},[_c('a-divider'),_c('a-col',{attrs:{"xs":24,"sm":24,"md":12}},[_c('a-form-item',[_c('p',{class:_vm.$style.labelInput},[_vm._v(_vm._s(_vm.$t('authForm.haveStore')))]),_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['have_store', {
              rules: [{
                required: _vm.metaRegisterConfig?.store?.required,
                validator: _vm.validatorHaveStore
              }],
              initialValue: true,
            }]),expression:"['have_store', {\n              rules: [{\n                required: metaRegisterConfig?.store?.required,\n                validator: validatorHaveStore\n              }],\n              initialValue: true,\n            }]"}]},[_c('a-radio',{attrs:{"value":true}},[_vm._v(" "+_vm._s(_vm.$t('form.yes'))+" ")]),_c('a-radio',{attrs:{"value":false}},[_vm._v(" "+_vm._s(_vm.$t('form.no'))+" ")])],1)],1)],1),_c('a-col',{attrs:{"xs":24,"sm":24,"md":12,"id":"store_image"}},[(_vm.form.getFieldValue('have_store'))?_c('a-form-item',[(!_vm.store_image)?_c('a-upload-dragger',{directives:[{name:"decorator",rawName:"v-decorator",value:(['store_image', {
              rules: [{
                required: _vm.form.getFieldValue('have_store'),
                message: `Gambar Toko tidak boleh kosong, jika anda memiliki toko.`,
              }],
            }]),expression:"['store_image', {\n              rules: [{\n                required: form.getFieldValue('have_store'),\n                message: `Gambar Toko tidak boleh kosong, jika anda memiliki toko.`,\n              }],\n            }]"}],staticClass:"image-uploader",attrs:{"name":"image","multiple":false,"show-upload-list":false,"list-type":"picture-card","beforeUpload":() => false},on:{"change":_vm.addImageStore}},[_c('a-spin',{attrs:{"spinning":_vm.loading_store_image}},[_c('p',{staticClass:"ant-upload-drag-icon"},[_c('a-icon',{attrs:{"type":"inbox"}})],1),_c('p',{staticClass:"ant-upload-text"},[_vm._v(" "+_vm._s(_vm.$t('form.clickOrDrag'))+" ")]),_c('p',{staticClass:"ant-upload-hint"},[_vm._v(" "+_vm._s(_vm.$t('form.infoUpload'))+" ")])])],1):_vm._e()],1):_vm._e(),(_vm.store_image && _vm.store_image.url && _vm.form.getFieldValue('have_store'))?_c('div',{staticClass:"d-flex justify-content-center"},[_c('img',{staticStyle:{"width":"25rem","height":"auto"},attrs:{"src":_vm.store_image.url}})]):_vm._e(),(_vm.store_image && _vm.store_image.url && _vm.form.getFieldValue('have_store'))?_c('div',{staticClass:"d-flex justify-content-center align-items-center mt-3",on:{"click":function($event){$event.preventDefault();return (() => _vm.store_image = null).apply(null, arguments)}}},[_c('div',{staticClass:"button--changeImage d-flex justify-content-center align-items-center"},[_vm._v(" "+_vm._s(_vm.$t('form.changeImage'))+" ")])]):_vm._e()],1)],1):_vm._e(),(_vm.checkRegisterConfig('store_online'))?_c('a-row',{attrs:{"gutter":[16, 16]}},[_c('a-divider'),_c('a-col',{attrs:{"xs":24,"sm":24,"md":12}},[_c('a-form-item',[_c('p',{class:_vm.$style.labelInput},[_vm._v(_vm._s(_vm.$t('authForm.haveOnlineStore')))]),_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['have_online_store', {
              rules: [{
                required: _vm.metaRegisterConfig?.online_store?.required,
                validator: _vm.validatorHaveOnlineStore
              }],
              initialValue: true,
            }]),expression:"['have_online_store', {\n              rules: [{\n                required: metaRegisterConfig?.online_store?.required,\n                validator: validatorHaveOnlineStore\n              }],\n              initialValue: true,\n            }]"}]},[_c('a-radio',{attrs:{"value":true}},[_vm._v(" "+_vm._s(_vm.$t('form.yes'))+" ")]),_c('a-radio',{attrs:{"value":false}},[_vm._v(" "+_vm._s(_vm.$t('form.no'))+" ")])],1)],1)],1),(_vm.form.getFieldValue('have_online_store'))?_c('a-col',{attrs:{"xs":24,"sm":24,"md":12}},[_vm._l((_vm.listOnlineStore),function(item,index){return _c('a-row',{key:index,staticClass:"mb-3"},[_c('a-col',{attrs:{"span":20}},[_c('a',{attrs:{"href":item.url,"target":"_blank"}},[_vm._v(" "+_vm._s(item.url)+" ")])]),_c('a-col',{staticClass:"text-right",staticStyle:{"cursor":"pointer"},attrs:{"span":4},on:{"click":function($event){$event.preventDefault();return _vm.handleDeleteListOnlineStore(index)}}},[_c('a-icon',{attrs:{"type":"delete"}})],1)],1)}),_c('a-row',{staticClass:"mb-3",attrs:{"id":"inputStore"}},[_c('a-col',{attrs:{"span":20}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['input_online_store', {
                  rules: [{
                    required: _vm.form.getFieldValue('have_online_store'),
                    validator: _vm.validatorListUrlStore
                  }],
                }]),expression:"['input_online_store', {\n                  rules: [{\n                    required: form.getFieldValue('have_online_store'),\n                    validator: validatorListUrlStore\n                  }],\n                }]"}],staticClass:"input-store",staticStyle:{"width":"95%","broder-color":"#000000 !important"},attrs:{"placeholder":_vm.$t('form.placeholderUrlStore')}})],1)],1),_c('a-col',{staticClass:"d-flex justify-content-center align-items-center",attrs:{"span":4}},[_c('div',{staticClass:"button--addStore d-flex justify-content-center align-items-center",on:{"click":function($event){$event.preventDefault();return _vm.handleClickAddOnlineStore.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('form.add'))+" ")])])],1)],2):_vm._e()],1):_vm._e(),_c('a-divider'),_c('a-row',{attrs:{"gutter":[16, 16]}},[_c('a-col',{attrs:{"xs":24,"sm":24,"md":12}},[_c('a-form-item',[_c('label',{class:_vm.$style.labelInput},[_vm._v(_vm._s(_vm.$t('form.password')))]),_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:(['password', {
              rules: [{
                required: true,
                message: `${_vm.$t('form.password')} tidak boleh kosong`,
              }],
              initialValue: _vm.password,
            }]),expression:"['password', {\n              rules: [{\n                required: true,\n                message: `${$t('form.password')} tidak boleh kosong`,\n              }],\n              initialValue: password,\n            }]"}],staticStyle:{"width":"95%"},attrs:{"placeholder":_vm.$t('form.placeholder_password')}})],1)],1),_c('a-col',{attrs:{"xs":24,"sm":24,"md":12}},[_c('a-button',{staticClass:"btn-submit-register",attrs:{"type":"primary","html-type":"submit","block":""}},[(!_vm.loading)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('form.registerAccount'))+" ")]):_vm._e(),(_vm.loading)?_c('div',[_c('a-icon',{attrs:{"type":"loading"}})],1):_vm._e()])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }